@import url('https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&subset=devanagari,latin-ext');

body {
  font-family: 'Poppins', sans-serif;
  background-color: #1c1b1b;
  color: #fffcfc;
}

/* .page {
  /* text-decoration:none; */
  /* border-bottom: 1px solid #ef8b80;
  box-shadow: inset 0 -4px 0 #ef8b80;
  color: inherit;
  transition: background 0.1s cubic-bezier(.33,.66,.66,1); 
}

*/
.logo {
  transition: transform .4s ease-in-out;
}
.logo:hover {
  transform: rotate(360deg);
}


.page:hover {
  background: #1dbc8d; 
} 

.b:hover {
  background: #1d9cbc;
}

.p:hover {
  background: #9336ac;
}

.y:hover {
  background: #bb4444;
}
.h:hover {
  background: #FFC0CB;
}
.topnav {
  background-color: #2a2929;
  overflow: hidden;
  display:block;
  text-align:center;
  z-index: 1;
}

.txv {
  background-color: #1dbc8d;
  transition: 500ms;
}

.tvv {
  background-color: #1d9cbc;
  transition: 500ms;
}


.topnav a {
  float: left;
  color: #fffcfc;
  padding: 14px 16px;
  text-decoration: none;
}

.title {
  font-size: 50px;
  font-weight: bold;
  padding: 2px 3px;
} 

.pages {
  font-size: 20px;
  font-weight: bold;
  padding: 20px 0px;
}

button {
  display: none;
}

.men {
  display: none;
}

.topnav a.split {
  float: right;
  color: white;
}

.topnav a.special {
  float: right;
  color: white;
}  

.not-showing {
  display: none;
}

.showing {
  background-color: #2a2929;
  margin-top: 100px;
  width: 100%;
  height: 320px;
  z-index: 999;
}

.mobile-pages {
  display: block;
  text-align: center;
  font-size: 30px;
  width: 100%;
}

.one, .two, .three, .four {
  font-weight: bold;
  width: 100%;
}

strong {
  position: relative;
}

strong::before {
  content: "";
  position: absolute;
  width: calc(100% + 4px);
  height: 30%;
  left: 0px;
  bottom: 5px;
  z-index: -1;
  transform: rotate(0deg);
}

.green::before {
  background-color: #1dbc8d;
}

.blue::before {
  background-color: #1d9cbc;
}

.purple::before {
  background-color: #9336ac;
}

.red::before {
  background-color: #bb4444;
}

.yellow::before {
  background-color: #1d4185;
}

.other::before {
  background-color: coral;
}
.hue::before {
  background-color: #fd6c9e	;
}
.ye::before {
  background-color: #CBB537 	;
}
.wo::before {
  background-color: #A4B748 	;
}
.cso-desc {
  float: left;
}

.promo-vid {
  text-align: center;
}

iframe{
  width: 80vw;
  height: calc(80vw/1.77);
}

.first-section {
  display: flex;
  justify-content: space-around;
  align-items: inherit;
  flex-wrap: wrap;
  /* background-color: #999; */
}

.cso-desc {
  text-align: center;
  width: 47.0%;
}
.slider-item {
  /* background-color: #ddd; */
  width: 45%;
  text-align: center;
  /* height: 300px; */
}

.third-section {
  display: flex;
  /* justify-content: space-around; */
  align-items: inherit;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.fb-group {
  text-align: center;
}

.fb {
  text-align: center;
  margin-top: -10px;
  margin-left: 60px;
}

.upcoming-events {
  /* background-color: purple; */
  text-align: center; 
  margin-top: 30px;
  width: 55.0%;
}

/* *, *:before, *:after {
  box-sizing: border-box;
} */


/* EVENTS */

.events-container {
  color: #c4c4c4;
  font-weight: bold;
  display: grid;
  margin: 40px;
  grid-template-columns: 1fr 6fr;
  grid-template-rows: repeat(3, 50px);
  grid-gap: 20px;
}

.ev2 {
  margin-top: 10px;
}

.ev3 {
  margin-top: 15px;
}

.events-title {
  color: #c4c4c4;
  text-align: left;
  font-size: 30px;
  font-weight: bold;
}

.events {
  padding-left: 0px;
}

.events-desc {
  font-weight: normal;
  text-align: left;
  font-size: 14px;
}

.events-sub-title {
  color: #fffcfc;
  text-align: left;
}

.date {
  color: #fffcfc;
}



/* ABOUT PAGE ABOUT PAGE ABOUT PAGE */

.left, .right {
  display: flex;
  justify-content: space-around;
  align-items: inherit;
  flex-wrap: wrap;
}

.left-content, .right-content {
  margin-top: 20px;
}

.left-pic, .right-pic {
  margin-top: 40px;
}

.right-pic {
  margin-left: 30px;
}

.left-pic {
  margin-right: 30px;
}

.left-content, .right-content {
  text-align: center;
  width: 52.5%;
}



/* COMMITTEES PAGE COMMITTEES PAGE COMMITTEES PAGE COMMITTEES PAGE COMMITTEES PAGE  */

.committees-content {
  text-align: center;
}

.hoverer {
  color: #fffcfc;
  text-decoration: none;
  cursor: pointer;
}

.first-row {
  display: flex;
  justify-content: space-around;
  align-items: inherit;
  flex-wrap: wrap;
}

.comm-desc {
  text-align: center;
  width: 50%;
}

.collapse-content {
  margin: 50px;
}

.second-row {
  display: flex;
  justify-content: space-around;
  align-items: inherit;
  flex-wrap: wrap;
}

.t-d {
  margin-top: 80px;
  text-align: center;
  width: 50%;
}

/* FOOTER */

.footer {
  display: flex;
  flex-flow: row wrap;
  padding: 30px 30px 20px 30px;
  color: #fffcfc;
  background-color: #1c1b1b;
  /* border-top: 1px solid #e5e5e5; */
}

.footer > * {
  flex:  1 100%;
}

.footer__addr {
  margin-right: 20em;
  margin-bottom: 1em;
}

.footer__logo {
  font-weight: bold;
  font-size: 2.5rem;
}

.footer__addr h2 {
  margin-top: 1em;
  font-size: 15px;
  font-weight: 400;
}

.nav__title {
  font-weight: 400;
  font-size: 15px;
}

.footer address {
  font-style: normal;
  color: #999;
}

.footer__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  max-width: max-content;
  background-color: #fffcfc;
  border-radius: 100px;
  color: #2f2f2f;
  line-height: 0;
  margin: 1em 0;
  font-size: 1rem;
  padding: 0 1.3em;
}

.footer ul {
  list-style: none;
  padding-left: 0;
}

.footer li {
  line-height: 2em;
}

.footer a {
  text-decoration: none;
}

.footer__nav {
  display: flex;
	flex-flow: row wrap;
}

.footer__nav > * {
  flex: 1 0%;
  margin-right: 1.25em;
}

.nav__ul a {
  color: #999;
}

/* .legal {
  display: flex;
  flex-wrap: wrap;
  color: #999;
} */
  
/* .legal__links {
  display: flex;
  text-align: right;
} */

.heart {
  color: #2f2f2f;
}



@media screen and (max-width: 925px) {
  .logo {
    margin-top: 5px;
    width: 50px;
  }

  .title {
    margin-top: 8px;
    font-size: 25px;
  }

  .pages {
    margin-top: -14px;
    font-size: 15px;
  }
}

@media only screen and (max-width: 925px) {
  .first-section, .third-section, .promo-vid {
    margin-left: 40px;
  }

  button, .men {
    width: 30px;
    display: flex;
    float: right;
    margin-top: 14px;
    margin-right: 10px;
    cursor: pointer;
  }

  * {
    text-align: center;
  }

  .slider-item, .cso-desc, .upcoming-events, .events-container {
    width: 90%;
  }

  .fb {
    margin-left: auto;
    margin-right: auto;
    margin-top: 150px;
  }

  .upcoming-head {
    margin-left: 20px;
    width: 100%;
  }

  .date {
    margin-left: -50px;
  }

  .event {
    margin-left: -30px;
  }

  .events-desc {
    width: 120%;
  }

  /* .ev1 {
    margin-top: 10px;
  } */

  .ev2 {
    margin-top: 30px;
  }

  .ev3 {
    margin-top: 80px;
  }

  .pages {
    display: none;
  }


  .content {
    width: 90%;
  }

  .pic {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .t::before {
    background-color: #1c1b1b;
  }

  .comm-desc {
    text-align: center;
    width: 100%;
    margin-right: -40px;
  }

  .comm-desc img {
    text-align: center;
  }

  .t-d {
    margin-top: 0%;
    width: 90%;
  }

  .committees-content img {
    margin-top: 20px;
    margin-left: 30px;
  }

  

  .contac, .footer__logo {
    /* margin-left: 70%; */
    display: none;
  }

  .address {
    display: none;
  }

  .footer__btn {
    width: 400px;
  }
}



@media screen and (min-width: 24.375em) {
  .legal .legal__links {
    margin-left: auto;
  }
}

@media screen and (min-width: 40.375em) {
  .footer__nav > * {
    flex: 1;
  }
  
  .nav__item--extra {
    flex-grow: 2;
  }
  
  .footer__addr {
    flex: 0%;
  }
  
  .footer__nav {
    flex: 2 0px;
  }
}


/* @media only screen and (max-width: 1024px) {
  #fb {
      display: none;
  }

  #slider-item {
    display: none;
  }

  #cso-desc {
    width: 90%;
  }
  
  #upcoming-events {
    width: 90%
  }

  .content, .comm-desc, .t-d {
    width: 90%;
  }

  strong::before {
    background-color: #fff;
  }
} */


